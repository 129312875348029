const ENV = {
  LCL: {
    REACT_APP_API_SERVER: 'https://stg-edu-gakutore-api.bappartners.com',
    SCORING_DOMAIN: 'https://stg-edu-auto-marking-web.bappartners.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/edu-pdf-tool-stg/o/',
    SCHOOL_ID_NO_2: '1075',
    SCHOOL_ID_NO_1: '1075',
    USER_ID_NO_2: [
      '0915004',
      '20000952',
      '22004861'
    ],
    USER_ID_NO_1: [
      '0915004',
      '20000952',
      '22004861'
    ]
  },

  DEV: {
    REACT_APP_API_SERVER: 'https://dev-edu-gakutore-api.bappartners.com',
    SCORING_DOMAIN: 'https://dev-edu-auto-marking-web.bappartners.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/edu-pdf-tool-dev/o/'
  },

  STG: {
    REACT_APP_API_SERVER: 'https://stg-edu-gakutore-api.bappartners.com',
    SCORING_DOMAIN: 'https://stg-edu-auto-marking-web.bappartners.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/edu-pdf-tool-stg/o/',
    SCHOOL_ID_NO_2: '1075',
    SCHOOL_ID_NO_1: '1075',
    USER_ID_NO_2: [
      '0915004',
      '20000952',
      '22004861'
    ],
    USER_ID_NO_1: [
      '0915004',
      '20000952',
      '22004861'
    ]
  },

  PRO: {
    REACT_APP_API_SERVER: 'https://ai-api.atwill-net.com',
    SCORING_DOMAIN: 'https://ai-scan.atwill-net.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/sanarucloud/b/ai_gakutore/o/',
    SCHOOL_ID_NO_2: '7001',
    SCHOOL_ID_NO_1: '7001',
    USER_ID_NO_2: [
      '0003',
      '0010',
      '0004'
    ],
    USER_ID_NO_1: [
      '0003',
      '0010',
      '0004'
    ]
  }
};

const config = ENV[process.env.REACT_APP_STAGE || 'LCL'];

const getEnv = (name, defaultValue) =>
  process.env[name] || config[name] || defaultValue;

export { getEnv };
