/* eslint-disable no-buffer-constructor */

import { getEnv } from "../configs/env";

/* eslint-disable no-plusplus */
const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI);
  const buffer = new Buffer(byteString, 'base64')
  const blob = new Blob([buffer], { type: 'application/pdf;base64' });
  return window.URL.createObjectURL(blob);
}

const params = (payload = {}) => {
  let url = '';
  const { length } = Object.keys(payload);
  if (length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, [key, value]] of Object.entries(payload).entries()) {
      url = `${url + key}=${value}`;
      if (index < length - 1) url += '&';
    }
  }

  return url;
};

const convertFullWidthToHalfWidth = (str) => {
  if (str) {
    return str.replace(/[！-～]/g, (r) => String.fromCharCode(r.charCodeAt(0) - 0xfee0));
  }
  return str;
}

const getConfigNo2 = (schoolId, userId) => {
  const schoolIdNo2 = getEnv("SCHOOL_ID_NO_2");
  return schoolIdNo2 === schoolId || userId && getEnv("USER_ID_NO_2").includes(userId) ;
}

const getConfigNo1 = (schoolId, userId) => {
  const schoolIdNo1 = getEnv("SCHOOL_ID_NO_1");
  return schoolIdNo1 === schoolId || userId && getEnv("USER_ID_NO_1").includes(userId) ;
}

export { dataURItoBlob, params, convertFullWidthToHalfWidth, getConfigNo2, getConfigNo1 }
